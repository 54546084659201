import { DateTime } from "luxon";
import {
  BingoGetUserElectricUsageDetailQuery,
  BingoGetUserElectricUsagePerDateQueryVariables,
  BingoGetUserElectricUsagePerDateQuery,
  BingoGetAreaTemperatureByAreaAndDateQueryVariables,
  BingoGetAreaTemperatureByAreaAndDateQuery,
  BingoGetUserElectricUsageSpecificDateQueryVariables,
  useBingoGetLastUserElectricityDataQuery,
  BingoGetUserElectricUsageSpecificDatePattern5QueryVariables,
} from "../../services/graphql/enhanceApi";

export type DetailTableRow = {
  from: string;
  to?: string;
  usage?: number;
  temperature?: {
    min: number | null;
    max: number | null;
  };
};

export type DetailDataEntity = {
  detailTable: Array<DetailTableRow>;
  targetDate?: DateTime;
};

export type DetailData = {
  current: DetailDataEntity;
  previous?: DetailDataEntity;
};

const DATE_HOUR_MINUITE_FORMAT = "yyyyMMddHHmm";
const DAILY_ROW_FORMAT = "H':'mm";
const DAILY_DISPLAY_DATE_FORMAT = "yyyy'年'M'月'd'日'";

// export const DEFAULT_AMPERE = "30";
// export const UNIT_CHARGE_DEFAULT_INDEX = 1;

export const createDailyDetailDataTwo = (
  targetDate: DateTime,
  currentData: BingoGetUserElectricUsageDetailQuery
): DetailData => {
  return {
    current: createDetailDataEntityTwo(targetDate, currentData),
  };
};

export const createDailyDetailDataThree = (
  targetDate: DateTime,
  currentData: BingoGetUserElectricUsageDetailQuery
): DetailData => {
  return {
    current: createDetailDataEntityThree(targetDate, currentData),
  };
};

export const createDailyDetailDataFour = (
  targetDate: DateTime,
  prevTargetDate: DateTime,
  currentData: BingoGetUserElectricUsageDetailQuery,
  previous: BingoGetUserElectricUsageDetailQuery
): DetailData => {
  return {
    current: createDetailDataEntityFour(targetDate, currentData),
    previous: createDetailDataEntityFour(prevTargetDate, previous),
  };
};

const createDetailDataEntityTwo = (
  targetDate: DateTime,
  apiResult: BingoGetUserElectricUsageDetailQuery
): DetailDataEntity => {
  // 日の合計を算出
  let usageSum = 0;
  const detailTable: Array<DetailTableRow> = [];
  // データ不足時は補正する
  const correctedArray = correctDailyDataArrayTwo(targetDate, apiResult);
  correctedArray.forEach(({ targetDateTime, usage }) => {
    if (typeof usage === "number") usageSum += usage;
    const row: DetailTableRow = {
      from: targetDateTime.toFormat("H"),
      to: targetDateTime.plus({ hour: 1 }).toFormat(DAILY_ROW_FORMAT),
      usage: usage,
    };
    detailTable.push(row);
  });

  // 最終行を24時に置き換え
  const correctLast = detailTable.pop();
  const lastRow: DetailTableRow = {
    from: correctLast?.from || "",
    to: correctLast?.to?.replace(/^0:/, "24:") || "",
    usage: correctLast?.usage,
  };
  detailTable.push(lastRow);
  const isDataEmpty =
    !apiResult.zerocame_view_user_electricity_data ||
    apiResult.zerocame_view_user_electricity_data.length < 1;

  if (isDataEmpty) {
    // データが1件も存在しない場合
    return {
      detailTable,
      targetDate: correctedArray[0].targetDateTime,
    };
  }

  return {
    detailTable,
    targetDate: correctedArray[0].targetDateTime,
  };
};

const createDetailDataEntityThree = (
  targetDate: DateTime,
  apiResult: BingoGetUserElectricUsageDetailQuery
): DetailDataEntity => {
  // 日の合計を算出
  let usageSum = 0;
  const detailTable: Array<DetailTableRow> = [];
  // データ不足時は補正する
  const correctedArray = correctDailyDataArrayThree(apiResult, targetDate);
  correctedArray.forEach(({ targetDateTime, usage }) => {
    if (typeof usage === "number") usageSum += usage;
    const row: DetailTableRow = {
      from: targetDateTime.toFormat("H"),
      to: targetDateTime.plus({ hour: 1 }).toFormat(DAILY_ROW_FORMAT),
      usage: usage,
    };
    detailTable.push(row);
  });

  // 最終行を24時に置き換え
  const correctLast = detailTable.pop();
  const lastRow: DetailTableRow = {
    from: correctLast?.from || "",
    to: correctLast?.to?.replace(/^0:/, "24:") || "",
    usage: correctLast?.usage,
  };
  detailTable.push(lastRow);
  const isDataEmpty =
    !apiResult.zerocame_view_user_electricity_data ||
    apiResult.zerocame_view_user_electricity_data.length < 1;

  if (isDataEmpty) {
    // データが1件も存在しない場合
    return {
      detailTable,
      targetDate: targetDate,
    };
  }

  return {
    detailTable,
    targetDate: targetDate,
  };
};

const createDetailDataEntityFour = (
  targetDate: DateTime,
  apiResult: BingoGetUserElectricUsageDetailQuery
): DetailDataEntity => {
  // 日の合計を算出
  let usageSum = 0;
  const detailTable: Array<DetailTableRow> = [];
  // データ不足時は補正する
  const correctedArray = correctDailyDataArrayTwo(targetDate, apiResult);
  correctedArray.forEach(({ targetDateTime, usage }) => {
    if (typeof usage === "number") usageSum += usage;
    const row: DetailTableRow = {
      from: targetDateTime.toFormat("H"),
      to: targetDateTime.plus({ hour: 1 }).toFormat(DAILY_ROW_FORMAT),
      usage: usage,
    };
    detailTable.push(row);
  });

  // 最終行を24時に置き換え
  const correctLast = detailTable.pop();
  const lastRow: DetailTableRow = {
    from: correctLast?.from || "",
    to: correctLast?.to?.replace(/^0:/, "24:") || "",
    usage: correctLast?.usage,
  };
  detailTable.push(lastRow);
  const isDataEmpty =
    !apiResult.zerocame_view_user_electricity_data ||
    apiResult.zerocame_view_user_electricity_data.length < 1;

  if (isDataEmpty) {
    // データが1件も存在しない場合
    return {
      detailTable,
      targetDate: correctedArray[0].targetDateTime,
    };
  }

  return {
    detailTable,
    targetDate: correctedArray[0].targetDateTime,
  };
};

const DATA_TIME_ARRAY = [
  "0000",
  "0030",
  "0100",
  "0130",
  "0200",
  "0230",
  "0300",
  "0330",
  "0400",
  "0430",
  "0500",
  "0530",
  "0600",
  "0630",
  "0700",
  "0730",
  "0800",
  "0830",
  "0900",
  "0930",
  "1000",
  "1030",
  "1100",
  "1130",
  "1200",
  "1230",
  "1300",
  "1330",
  "1400",
  "1430",
  "1500",
  "1530",
  "1600",
  "1630",
  "1700",
  "1730",
  "1800",
  "1830",
  "1900",
  "1930",
  "2000",
  "2030",
  "2100",
  "2130",
  "2200",
  "2230",
  "2300",
  "2330",
] as const;

const correctDailyDataArrayTwo = (
  targetDate: DateTime,
  {
    zerocame_view_user_electricity_data: dataArray,
  }: BingoGetUserElectricUsageDetailQuery
): {
  targetDateTime: DateTime;
  usage?: number;
}[] => {
  const correctedArray: { targetDateTime: DateTime; usage?: number }[] = [];
  if (dataArray.length !== 48) {
    DATA_TIME_ARRAY.forEach((time, index) => {
      const data = dataArray.find(
        // (data) => data.target_date_hour_minute.slice(-4) == time
        (data) => Number(data.time_code).valueOf() === index + 1
      );
      if (data) {
        correctedArray.push({
          targetDateTime: DateTime.fromFormat(
            data.get_ymd + time,
            DATE_HOUR_MINUITE_FORMAT
          ),
          usage: data.min_30_energy as number,
        });
      } else {
        correctedArray.push({
          targetDateTime: DateTime.fromFormat(
            targetDate.toFormat("yyyyMMdd") + time,
            DATE_HOUR_MINUITE_FORMAT
          ),
          usage: undefined,
        });
      }
    });
  } else {
    dataArray.forEach((data, index) => {
      if (dataArray[0].get_ymd === data.get_ymd) {
        correctedArray.push({
          targetDateTime: DateTime.fromFormat(
            data.get_ymd + DATA_TIME_ARRAY[index],
            DATE_HOUR_MINUITE_FORMAT
          ),
          usage: data.min_30_energy as number,
        });
      } else {
        correctedArray.push({
          targetDateTime: DateTime.fromFormat(
            dataArray[0].get_ymd + DATA_TIME_ARRAY[index],
            DATE_HOUR_MINUITE_FORMAT
          ),
          usage: 0 as number,
        });
      }
    });
  }

  // 30分単位から1時間単位に変換する
  const list: { targetDateTime: DateTime; usage?: number }[] = [];
  for (let i = 0; i < 24; ++i) {
    const data1 = correctedArray[i * 2];
    const data2 = correctedArray[i * 2 + 1];
    list.push({
      targetDateTime: data1.targetDateTime,
      usage: (data1.usage || 0) + (data2.usage || 0),
    });
  }

  return list;
};

const correctDailyDataArrayThree = (
  {
    zerocame_view_user_electricity_data: dataArray,
  }: BingoGetUserElectricUsageDetailQuery,
  targetDate: DateTime
): {
  targetDateTime: DateTime;
  usage?: number;
}[] => {
  const correctedArray: { targetDateTime: DateTime; usage?: number }[] = [];
  const day =
    dataArray.length > 0
      ? DateTime.fromFormat(
          dataArray[dataArray.length - 1].get_ymd || "",
          "yyyyMMdd"
        )
      : targetDate;
  const prevDay = day.minus({ day: 1 });
  const prevDayStr = prevDay.toFormat("yyyyMMdd");
  const dataArrayCopy = dataArray.concat();
  if (dataArray.length !== 96) {
    // 取得データが歯抜けの場合、0埋めする
    // let dataArrayCopy = dataArray.concat();
    for (let i = 1; i <= 48; i++) {
      const index = i.toString().padStart(2, "0");
      const isArrayData = dataArray.some((data) => {
        return (
          data.time_code === index &&
          data.get_ymd === dataArray[dataArray.length - 1].get_ymd
        );
      });
      if (!isArrayData) {
        dataArrayCopy.push({
          get_ymd: day.toFormat("yyyyMMdd"),
          time_code: index,
          min_30_energy: 0,
        });
      }
    }
    for (let i = 1; i <= 48; i++) {
      const index = i.toString().padStart(2, "0");
      const isArrayData = dataArray.some((data) => {
        return data.time_code === index && data.get_ymd === prevDayStr;
      });
      const isAfterDay = dataArray.some((data) => data.get_ymd === prevDayStr);
      if (!isArrayData || !isAfterDay) {
        dataArrayCopy.push({
          get_ymd: prevDayStr,
          time_code: index,
          min_30_energy: 0,
        });
      }
    }
    // dataArray = dataArrayCopy;
  }
  dataArrayCopy.sort(
    (a, b) =>
      Number(DateTime.fromFormat(a.get_ymd || "", "yyyyMMdd")) -
      Number(DateTime.fromFormat(b.get_ymd || "", "yyyyMMdd"))
  );
  dataArrayCopy.forEach((data, index) => {
    const timeCode = Number(data.time_code);
    if (
      dataArrayCopy[dataArrayCopy.length - 1].get_ymd === data.get_ymd &&
      timeCode <= 34
    ) {
      correctedArray.push({
        targetDateTime: DateTime.fromFormat(
          data.get_ymd + DATA_TIME_ARRAY[timeCode - 1],
          DATE_HOUR_MINUITE_FORMAT
        ),
        usage: data.min_30_energy as number,
      });
    } else if (prevDayStr === data.get_ymd && timeCode >= 33 && timeCode < 49) {
      correctedArray.push({
        targetDateTime: DateTime.fromFormat(
          data.get_ymd + DATA_TIME_ARRAY[timeCode - 1],
          DATE_HOUR_MINUITE_FORMAT
        ),
        usage: data.min_30_energy as number,
      });
    }
  });
  correctedArray.sort(
    (a, b) =>
      Number(a.targetDateTime.toFormat("yyyyMMddHHmm")) -
      Number(b.targetDateTime.toFormat("yyyyMMddHHmm"))
  );

  // 30分単位から1時間単位に変換する
  const list: { targetDateTime: DateTime; usage?: number }[] = [];
  for (let i = 0; i < correctedArray.length / 2; ++i) {
    const data1 = correctedArray[i * 2];
    const data2 = correctedArray[i * 2 + 1];
    list.push({
      targetDateTime: data1.targetDateTime,
      usage: (data1?.usage || 0) + (data2?.usage || 0),
    });
  }

  return list;
};

// export const dummyDate = DateTime.fromJSDate(new Date());

export const createWeeklyQueryArgs = (
  consentApplicationResultId?: number,
  toDate?: DateTime
): BingoGetUserElectricUsagePerDateQueryVariables => {
  if (!consentApplicationResultId || !toDate) {
    return {
      consentApplicationResultId: -1,
      date_from: "",
      date_to: "",
    };
  } else
    return {
      consentApplicationResultId,
      date_from: toDate.minus({ day: 8 }).toFormat("yyyyMMdd"),
      date_to: toDate.minus({ day: 1 }).toFormat("yyyyMMdd"),
    };
};

export const createWeeklyTemperatureQueryArgs = (
  target: "current" | "previous",
  areaCode?: string,
  targetDate?: DateTime
): BingoGetAreaTemperatureByAreaAndDateQueryVariables => {
  if (!areaCode || !targetDate) {
    return {
      areaCode: "",
      dateFrom: "",
      dateTo: "",
    };
  } else if (target === "current") {
    // targetDateが2023/11/25の場合
    return {
      areaCode: areaCode,
      dateFrom: targetDate.minus({ day: 8 }).toFormat("yyyyMMdd"), // 2023/11/17
      dateTo: targetDate.minus({ day: 1 }).toFormat("yyyyMMdd"), //2023/11/24
    };
  } else {
    // previous
    return {
      areaCode: areaCode,
      dateFrom: targetDate.minus({ week: 1 }).toFormat("yyyyMMdd"),
      dateTo: targetDate.minus({ day: 1 }).toFormat("yyyyMMdd"),
    };
  }
};

export const createWeeklyDetailData = (
  startDate: DateTime,
  currentData: BingoGetUserElectricUsagePerDateQuery,
  currentTemperature: BingoGetAreaTemperatureByAreaAndDateQuery
): DetailData => {
  return {
    current: createWeeklyDetailDataEntity(
      currentData,
      startDate,
      currentTemperature
    ),
  };
};

export type DataSet = {
  usage?: number;
  tempMin?: number | null;
  tempMax?: number | null;
};
export const extractDataSet = (
  usage: number,
  targetDate?: DateTime,
  temperatureData?: BingoGetAreaTemperatureByAreaAndDateQuery
): DataSet => {
  if (targetDate && temperatureData) {
    const targetTemp =
      temperatureData?.zerocame_area_master[0].jma_area_master.jma_meteorological_dailies.find(
        (data) => data.date == targetDate.toFormat("yyyyMMdd")
      );
    return {
      usage,
      tempMin:
        targetTemp && typeof targetTemp.air_temperature_min !== "undefined"
          ? (targetTemp.air_temperature_min as number)
          : null,
      tempMax:
        targetTemp && typeof targetTemp.air_temperature_max !== "undefined"
          ? (targetTemp.air_temperature_max as number)
          : null,
    };
  } else {
    return {
      usage,
    };
  }
};

const createWeeklyDetailDataEntity = (
  targetData: BingoGetUserElectricUsagePerDateQuery,
  startDate: DateTime,
  temperatureData: BingoGetAreaTemperatureByAreaAndDateQuery
): DetailDataEntity => {
  const detailTable = Array<DetailTableRow>();

  const usageArray: Array<DataSet> = [];
  for (let i = 0; i < 8; i++) {
    const targetWeekDate = startDate.minus({ day: i + 1 });
    const data = targetData.zerocame_fn_user_electricity_data_per_day.find(
      (record) => record.get_ymd == targetWeekDate.toFormat("yyyyMMdd")
    );
    usageArray.push(
      extractDataSet(data?.daily_energy, targetWeekDate, temperatureData)
    );
  }

  let usageSum = 0;
  let dataCount = 0;
  for (let i = 0; i < usageArray.length; i++) {
    const data = usageArray[i];
    detailTable.push(
      createDetailDataRow(
        startDate.minus({ day: i + 1 }),
        formatDateToMonthlyDataRow,
        data.usage,
        {
          max: data.tempMax || null,
          min: data.tempMin || null,
        }
      )
    );
    if (typeof data.usage === "number") {
      usageSum += data.usage;
      dataCount++;
    }
  }
  detailTable.reverse();

  if (dataCount === 0) {
    return {
      detailTable,
    };
  }

  return {
    detailTable,
  };
};

export const formatDateToMonthlyDataRow = (date: DateTime) =>
  date.toFormat("MM/dd");

export const createDetailDataRow = (
  date: DateTime,
  dateFormatter: (date: DateTime) => string,
  usage: number | undefined,
  temperature?: {
    min: number | null;
    max: number | null;
  }
): DetailTableRow => {
  return {
    from: dateFormatter(date),
    usage,
    temperature,
  };
};

export const createaWeekAgoQueryArgs = (
  consentApplicationResultId?: number,
  toDate?: DateTime
): BingoGetUserElectricUsageSpecificDateQueryVariables => {
  if (!consentApplicationResultId || !toDate) {
    return {
      consentApplicationResultId: -1,
      date_a_day_ago: "",
      date_a_week_ago: "",
    };
  } else
    return {
      consentApplicationResultId,
      date_a_day_ago: toDate.minus({ day: 1 }).toFormat("yyyyMMdd"),
      date_a_week_ago: toDate.minus({ day: 7 }).toFormat("yyyyMMdd"),
    };
};

export const useHasUserElectricityData = (
  consentApplicationResultId: number | undefined | null,
  isSkip: boolean
) => {
  const skip = isSkip || !consentApplicationResultId;
  const result = useBingoGetLastUserElectricityDataQuery(
    {
      consentApplicationResultId: consentApplicationResultId || 0,
    },
    {
      skip: skip,
      refetchOnMountOrArgChange: true,
    }
  );
  const data = result.data?.zerocame_view_user_electricity_data;
  return {
    // 取得中かどうか
    isFetching: result.isFetching,
    // 結果 (電力データが存在するかどうか)
    result: !!data && 0 < data.length,
  };
};

export const createaWeekAgoQueryArgsPattern5 = (
  consentApplicationResultId?: number,
  toDate?: DateTime
): BingoGetUserElectricUsageSpecificDatePattern5QueryVariables => {
  if (!consentApplicationResultId || !toDate) {
    return {
      consentApplicationResultId: -1,
      date_a_day_ago: "",
      date_a_two_days_ago: "",
      date_a_week_ago: "",
    };
  } else
    return {
      consentApplicationResultId,
      date_a_day_ago: toDate.minus({ day: 1 }).toFormat("yyyyMMdd"),
      date_a_two_days_ago: toDate.minus({ day: 2 }).toFormat("yyyyMMdd"),
      date_a_week_ago: toDate.minus({ day: 8 }).toFormat("yyyyMMdd"),
    };
};
