import { useMemo } from "react";
import {
  useBingoGetMyDataQuery,
  useGetConsentApplicationInfoForBingoQuery,
} from "../../services/graphql/enhanceApi";
import { useGreenActionAuth0 } from "./useGreenActionAuth0";
import { useHasUserElectricityData } from "../electricity";

// ステータス: 未連携
export const STATUS_YET = "YET";
// ステータス: 申請中
export const STATUS_PROCESSING = "PROCESSING";
// ステータス: 申請済み・結果待ち
export const STATUS_WAIT_RESULT = "WAIT_RESULT";
// ステータス: 連携済（全審査結果OK）
export const STATUS_OK = "OK";
// ステータス: 連携失敗（本人確認結果NG）
export const STATUS_NG_BY_IDENTIFICATION = "NG_BY_IDENTIFICATION";
// ステータス: 連携失敗（地点確認結果NG）
export const STATUS_NG_BY_POINT_CONFIRMATION = "NG_BY_POINT_CONFIRMATION";
// ステータス: 連携失敗（契約照合結果NG）
export const STATUS_NG_BY_CONTRACT_MATCHING = "NG_BY_CONTRACT_MATCHING";
// ステータス: ユーザによる取り消し
export const STATUS_CANCEL_BY_USER = "CANCEL_BY_USER";
// ステータス: 異動による取り消し
export const STATUS_CANCEL_BY_TRANSFER = "CANCEL_BY_TRANSFER";

// 同意申請審査結果: 申請中
const CONSENT_APPLICATION_STATUS_PROCESSING = "1";
// 同意申請審査結果: 申請完了
const CONSENT_APPLICATION_STATUS_DONE = "2";
// 同意申請審査結果: 審査済み
const CONSENT_APPLICATION_STATUS_RESULT_RECEIVED = "3";
// 同意申請審査結果: ユーザによる取り消し
const CONSENT_APPLICATION_STATUS_CANCEL_BY_USER = "4";
// 同意申請審査結果: 異動による取り消し
const CONSENT_APPLICATION_STATUS_CANCEL_BY_TRANSFER = "5";

type Info = {
  // Zerocame側のステータス
  consentApplicationZerocaStatus: string | null | undefined;
  // 同意申請結果ステータス
  consentApplicationStatus?: string | null | undefined;
  // 本人確認結果
  identificationResult?: string | null | undefined;
  // 地点確認結果
  pointConfirmationResult?: string | null | undefined;
  // 契約照合結果
  contractMatchingResult?: string | null | undefined;
};

export const useConsentApplicationInfo = () => {
  // ユーザIDの取得
  const auth0Result = useGreenActionAuth0();

  // ユーザIDを取得できなかったらスキップ
  const isSkip = !auth0Result.auth0UserId;

  // 同意申請と結果の情報の取得
  const getConsentApplicationInfoResult =
    useGetConsentApplicationInfoForBingoQuery(
      { auth0_user_id: auth0Result.auth0UserId as string },
      {
        skip: isSkip,
      }
    );

  // 同意申込結果取得
  const consentApplicationResultData = useBingoGetMyDataQuery(
    { auth0_user_id: auth0Result.auth0UserId as string },
    {
      refetchOnMountOrArgChange: true,
      skip: isSkip,
    }
  );

  // 同意申込結果IDが確定
  const consentApplicationResult = useMemo<
    { id: number; status: string } | undefined
  >(() => {
    if (
      consentApplicationResultData.data
        ?.zerocame_fn_current_consent_application_result[0]
    ) {
      const { consent_application_status, id } =
        consentApplicationResultData.data
          .zerocame_fn_current_consent_application_result[0];
      if (typeof id === "number") {
        return {
          id,
          status: consent_application_status,
        };
      }
    }
  }, [
    consentApplicationResultData.data
      ?.zerocame_fn_current_consent_application_result,
  ]);

  // 電力データ取得済みか
  const hasUserElectricityData = useHasUserElectricityData(
    consentApplicationResult?.id,
    isSkip || !auth0Result.isAccessTokenReady
  );

  let info: Info | undefined;
  if (
    getConsentApplicationInfoResult.isSuccess &&
    getConsentApplicationInfoResult.data
  ) {
    const zerocameUser = getConsentApplicationInfoResult.data.zerocame_users[0];
    if (zerocameUser) {
      if (zerocameUser.users_consent_application_results.length > 0) {
        const usersConsentApplicationResults =
          zerocameUser.users_consent_application_results[0];
        info = {
          consentApplicationZerocaStatus:
            zerocameUser.consent_application_zeroca_status,
          consentApplicationStatus:
            usersConsentApplicationResults.consent_application_status,
          identificationResult:
            usersConsentApplicationResults.identification_result,
          pointConfirmationResult:
            usersConsentApplicationResults.point_confirmation_result,
          contractMatchingResult:
            usersConsentApplicationResults.contract_matching_result,
        };
      } else {
        info = {
          consentApplicationZerocaStatus:
            zerocameUser.consent_application_zeroca_status,
        };
      }
    }
  }

  const status = (() => {
    if (!info) return;
    if (
      info.consentApplicationZerocaStatus ===
      CONSENT_APPLICATION_STATUS_PROCESSING
    ) {
      // 申込処理中
      return STATUS_PROCESSING;
    }

    if (
      info.consentApplicationZerocaStatus === CONSENT_APPLICATION_STATUS_DONE
    ) {
      // 同意申込完了＆結果未連携
      return STATUS_WAIT_RESULT;
    }

    if (
      info.consentApplicationZerocaStatus ===
      CONSENT_APPLICATION_STATUS_RESULT_RECEIVED
    ) {
      if (info.consentApplicationStatus === "1") {
        // 同意申込OK
        return STATUS_OK;
      }
      if (info.consentApplicationStatus === "2") {
        if (info.identificationResult !== "01") {
          // 本人確認NG
          return STATUS_NG_BY_IDENTIFICATION;
        }
        if (info.pointConfirmationResult !== "01") {
          // 地点確認NG
          return STATUS_NG_BY_POINT_CONFIRMATION;
        }
        if (info.contractMatchingResult !== "01") {
          // 契約照合NG
          return STATUS_NG_BY_CONTRACT_MATCHING;
        }
      }
    }

    if (
      info.consentApplicationZerocaStatus ===
      CONSENT_APPLICATION_STATUS_CANCEL_BY_USER
    ) {
      // ユーザによる取消
      return STATUS_CANCEL_BY_USER;
    }

    if (
      info.consentApplicationZerocaStatus ===
      CONSENT_APPLICATION_STATUS_CANCEL_BY_TRANSFER
    ) {
      // 異動による取消
      return STATUS_CANCEL_BY_TRANSFER;
    }

    // その他（同意申込未実施、申込処理中、同意取消）
    return STATUS_YET;
  })();

  return {
    status,
    hasUserElectricityData,
  };
};
