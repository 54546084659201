import { NextRouter, useRouter } from "next/router";
import { useCallback, useState } from "react";
import { MenuHandlers } from "../../components/organisms/Header";
import * as UseConsentApplicationInfo from "./useConsentApplicationInfo";
import { openCommonApp } from "../ui/route";

// 電力詳細画面を開く
const openElectricity = () => openCommonApp("/electricity");

// スマートメーター連携画面を開く
const openSmartMeterCooperation = (router: NextRouter) =>
  router.push("/smartMeterCooperation");

export const useMenuHandlersCreator = (): MenuHandlers => {
  const router = useRouter();
  const [
    isElectricityConnectionModalVisible,
    setElectricityConnectionModalVisible,
  ] = useState<boolean>(false);

  const consentApplicationInfo =
    UseConsentApplicationInfo.useConsentApplicationInfo();

  // 電機関連画面モーダルを表示する
  const showElectricityConnectionModal = () =>
    setElectricityConnectionModalVisible(true);

  // 電機関連画面モーダルを非表示にする
  const hideElectricityConnectionModal = () =>
    setElectricityConnectionModalVisible(false);

  // ヘッダーホーム押下時の遷移
  const homeHandler = useCallback(() => {
    router.push("/");
  }, [router]);

  // ヘッダーアクション押下時の遷移
  const actionTopHandler = useCallback(() => {
    router.push("/greenAction");
  }, [router]);

  // ヘッダー電力量押下時の遷移
  const powerHandler = () => {
    const status = consentApplicationInfo.status;
    const hasUserElectricityData = consentApplicationInfo.hasUserElectricityData.result;
    console.error(status);
    if (status === UseConsentApplicationInfo.STATUS_OK) {
      if (hasUserElectricityData) {
        // 電力データ連携済（全審査結果OK）のとき
        // 電力詳細画面が別タブで開く
        openElectricity();
      } else {
        // 電力データ未連携（全審査結果OK）のとき
        // 電機関連画面（モーダル）が開く
        showElectricityConnectionModal();
      }
    } else if (
      status === UseConsentApplicationInfo.STATUS_PROCESSING
    ) {
      // 電力データ連携確認中のとき
      // スマートメーター連携画面に遷移する
      openSmartMeterCooperation(router);
    } else if (
      status === UseConsentApplicationInfo.STATUS_WAIT_RESULT
    ) {
      // 電力データ結果取得待ちのとき
      // 電機関連画面（モーダル）が開く
      showElectricityConnectionModal();
    } else if (
      status === UseConsentApplicationInfo.STATUS_NG_BY_IDENTIFICATION
    ) {
      // 電力データ連携失敗（本人確認結果NG）のとき
      // スマートメーター連携画面に遷移する
      openSmartMeterCooperation(router);
    } else if (
      status === UseConsentApplicationInfo.STATUS_NG_BY_POINT_CONFIRMATION
    ) {
      // 電力データ連携失敗（地点確認結果NG）のとき
      // スマートメーター連携画面に遷移する
      openSmartMeterCooperation(router);
    } else if (
      status === UseConsentApplicationInfo.STATUS_NG_BY_CONTRACT_MATCHING
    ) {
      // 電力データ連携失敗（契約照合結果NG）のとき
      // スマートメーター連携画面に遷移する
      openSmartMeterCooperation(router);
    } else if (status === UseConsentApplicationInfo.STATUS_CANCEL_BY_TRANSFER) {
      // 電力データ連携失敗（引っ越し・住所変更）のとき
      // スマートメーター連携画面に遷移する
      openSmartMeterCooperation(router);
    } else {
      // 電力データ未連携（取消の場合含む）のとき
      // スマートメーター連携画面に遷移する
      openSmartMeterCooperation(router);
    }
  };

  // 電機関連画面モーダルが閉じられるとき
  const onCloseElectricityConnectionModal = useCallback(() => {
    hideElectricityConnectionModal();
  }, []);

  return {
    isElectricityConnectionModalVisible,
    actionTopHandler,
    homeHandler,
    powerHandler,
    onCloseElectricityConnectionModal,
  };
};
